@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
    height: 100vh;
    width: 100vw;
    background-color: #18181b;
    overflow: hidden ;
}
/*
#playlistHeader {
    background: #18181b url("../src/img/texture-2.jpg") no-repeat center center;
  }
*/
.queryBottom #limitNumber, .queryBottom select {
    width: 70px;
  }

.queryBottom input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}
.queryBottom input[type=number]::-webkit-inner-spin-button, 
.queryBottom input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }

.playlistTable > div {
  grid-template-columns: 1fr 4fr 4fr 1fr;
}

@media (max-width:768px) {
.search-item-3, .search-item-4 {
  display:none;
}
.playlistTable > div {
  grid-template-columns: 1fr;
}
}

  /* Playlist Created Popup */

  .popup-wrapper {
    background-color: rgba(0,0,0,0.5);
}
